/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useRef, useState } from "react";
import { Stage, Layer, Line, Rect } from "react-konva";
import SplashLogo from "./logo-splash.svg";
import TextDesignFutures from "./text-design-futures.svg";
import TextDesignImpact from "./text-design-impact.svg";
import TextDesignMarketplace from "./text-design-marketplace.svg";
// import { useSpring, animated } from "react-spring";

if (!Array.prototype.fill) {
  Object.defineProperty(Array.prototype, "fill", {
    value: function (value) {
      // Steps 1-2.
      if (this == null) {
        throw new TypeError("this is null or not defined");
      }

      var O = Object(this);

      // Steps 3-5.
      var len = O.length >>> 0;

      // Steps 6-7.
      var start = arguments[1];
      var relativeStart = start >> 0;

      // Step 8.
      var k =
        relativeStart < 0
          ? Math.max(len + relativeStart, 0)
          : Math.min(relativeStart, len);

      // Steps 9-10.
      var end = arguments[2];
      var relativeEnd = end === undefined ? len : end >> 0;

      // Step 11.
      var final =
        relativeEnd < 0
          ? Math.max(len + relativeEnd, 0)
          : Math.min(relativeEnd, len);

      // Step 12.
      while (k < final) {
        O[k] = value;
        k++;
      }

      // Step 13.
      return O;
    },
  });
}

export default function InteractiveFirstFold() {
  const [stageSize, setStageSize] = useState({ w: 0, h: 0 });
  const [center, setCenter] = useState({ x: 0, y: 0 });
  const [globalScale] = useState(0.72);
  const [subtitleScale] = useState(1);
  const textGap = 5;

  const [parallax1, setParallax1] = useState(0);
  const [parallax2, setParallax2] = useState(0);
  const [parallax3, setParallax3] = useState(0);

  const [dx, setDx] = useState(0);
  const [dy, setDy] = useState(1);

  const dyref = useRef();

  useEffect(() => {
    dyref.current = dy;
  }, [dy]);

  const [textRects, setTextRects] = useState({
    impact: { x: 0, y: 0, w: 0, h: 0 },
    marketplace: { x: 0, y: 0, w: 0, h: 0 },
    futures: { x: 0, y: 0, w: 0, h: 0 },
    title: { x: 0, y: 0, w: 0, h: 0 },
  });


  const updateDx = (e) => {
    setDx(1 * (e.clientX / window.innerWidth - 0.5));
  };

  const updateScroll = () => {
    const multiplier = (dyref.current - 0.5) * 2; // -11 to 1
    setParallax1(20 - 20 * multiplier);
    setParallax2(-15 + 20 * multiplier);
    setParallax3(-15 * Math.max(multiplier, 0));

    const text_di = document.getElementById("text--design-impact");
    const text_df = document.getElementById("text--design-futures");
    const text_dm = document.getElementById("text--design-marketplace");
    const text_title = document.getElementById("text--title");

    setTextRects({
      title: {
        x: text_title.offsetLeft,
        y: text_title.offsetTop,
        w: text_title.offsetWidth,
        h: text_title.offsetHeight,
      },
      impact: {
        x: text_di.offsetLeft,
        y: text_di.offsetTop,
        w: text_di.offsetWidth,
        h: text_di.offsetHeight,
      },
      marketplace: {
        x: text_dm.offsetLeft,
        y: text_dm.offsetTop,
        w: text_dm.offsetWidth,
        h: text_dm.offsetHeight,
      },
      futures: {
        x: text_df.offsetLeft,
        y: text_df.offsetTop,
        w: text_df.offsetWidth,
        h: text_df.offsetHeight,
      },
    });
  };

  const updateStageSize = () => {
    const s = document.getElementById("stageWrapper");
    const text_di = document.getElementById("text--design-impact");
    const text_df = document.getElementById("text--design-futures");
    const text_dm = document.getElementById("text--design-marketplace");
    const text_title = document.getElementById("text--title");
    setStageSize({ w: s.offsetWidth, h: s.offsetHeight });
    setCenter({ x: s.offsetWidth / 2, y: s.offsetHeight / 2 });
    setTextRects({
      title: {
        x: text_title.offsetLeft,
        y: text_title.offsetTop,
        w: text_title.offsetWidth,
        h: text_title.offsetHeight,
      },
      impact: {
        x: text_di.offsetLeft,
        y: text_di.offsetTop,
        w: text_di.offsetWidth,
        h: text_di.offsetHeight,
      },
      marketplace: {
        x: text_dm.offsetLeft,
        y: text_dm.offsetTop,
        w: text_dm.offsetWidth,
        h: text_dm.offsetHeight,
      },
      futures: {
        x: text_df.offsetLeft,
        y: text_df.offsetTop,
        w: text_df.offsetWidth,
        h: text_df.offsetHeight,
      },
    });
  };

  useEffect(() => {

    const observer = new IntersectionObserver(
      (entries) => {
        setDy(entries?.[0]?.intersectionRatio);
      },
      {
        threshold: new Array(200).fill(0).map((_, n) => n / 200),
      }
    );

    observer.observe(document.getElementById("stageWrapper"));
    window.addEventListener("resize", updateStageSize);
    document.addEventListener("mousemove", updateDx);

    function render() {
      updateScroll();
      window.requestAnimationFrame(render);
    }

    render();

    const timer = setInterval(() => {
      const text_di = document.getElementById("text--design-impact");
      const text_df = document.getElementById("text--design-futures");
      const text_dm = document.getElementById("text--design-marketplace");
      const text_title = document.getElementById("text--title");
      if (
        text_di.offsetWidth === 0 ||
        text_dm.offsetWidth === 0 ||
        text_df.offsetWidth === 0 ||
        text_title.offsetWidth === 0
      ) {
        return;
      } else {
        updateStageSize();
        clearInterval(timer);
      }
    }, 50);

    return () => {
      window.removeEventListener("resize", updateStageSize);
      document.removeEventListener("mousemove", updateDx);
      window.cancelAnimationFrame(render);
    };
  }, []);

  return (
    <div
      id="stageWrapper"
      css={css`
        width: 100%;
        height: 100%;
        position: relative;

        > div {
          position: relative;
          z-index: 1;
        }

        img {
          z-index: 3;
        }
      `}
    >
      <Stage width={stageSize.w} height={stageSize.h}>
        <Layer>
          <Line
            x={0}
            y={0}
            points={[
              textRects.impact.x + textRects.impact.w / 2 + textGap,
              textRects.impact.y - textRects.impact.h / 2,
              center.x - textRects.title.w / 10,
              center.y - (textRects.title.h / 10) * 2.05,
              center.x - (textRects.title.w / 10) * 3.4,
              center.y - (textRects.title.h / 10) * 2.05,

              center.x - (textRects.title.w / 10) * 3.7,
              center.y - (textRects.title.h / 10) * 2.4,
              textRects.impact.x - textRects.impact.w / 2,
              textRects.impact.y + textRects.impact.h / 2 + textGap,
              textRects.impact.x + textRects.impact.w / 2 + textGap,
              textRects.impact.y + textRects.impact.h / 2 + textGap,
            ]}
            strokeWidth={0}
            tension={0}
            closed
            fill="#ef4d5d"
          />

          <Line
            x={0}
            y={0}
            points={[
              textRects.futures.x + textRects.futures.w / 2,
              textRects.futures.y + textRects.futures.h / 2 + textGap,
              center.x + (textRects.title.w / 10) * 4.9,
              center.y - (textRects.title.h / 10) * 2.05,
              center.x + (textRects.title.w / 10) * 3.1,
              center.y - (textRects.title.h / 10) * 2.05,
              center.x + (textRects.title.w / 10) * 3.1,
              center.y - (textRects.title.h / 10) * 5,
              textRects.futures.x - textRects.futures.w / 2 - textGap,
              textRects.futures.y - textRects.futures.h / 2,
              textRects.futures.x - textRects.futures.w / 2 - textGap,
              textRects.futures.y + textRects.futures.h / 2 + textGap,
            ]}
            strokeWidth={0}
            tension={0}
            closed
            fill="#ef4d5d"
          />

          <Line
            x={0}
            y={0}
            points={[
              textRects.marketplace.x + textRects.marketplace.w / 2,
              textRects.marketplace.y - textRects.marketplace.h / 2 - textGap,
              textRects.marketplace.x - textRects.marketplace.w / 2 - textGap,
              textRects.marketplace.y - textRects.marketplace.h / 2 - textGap,
              textRects.marketplace.x - textRects.marketplace.w / 2 - textGap,
              textRects.marketplace.y + textRects.marketplace.h / 2,
              center.x + (textRects.title.w / 10) * 0.27,
              center.y + (textRects.title.h / 10) * 1.4,
              center.x + (textRects.title.w / 10) * 0.27,
              center.y - (textRects.title.h / 10) * 1.46,
              center.x + (textRects.title.w / 10) * 3,
              center.y - (textRects.title.h / 10) * 1.46,
            ]}
            strokeWidth={0}
            tension={0}
            closed
            fill="#ef4d5d"
          />

          <Line
            x={0}
            y={0}
            points={[
              center.x - (textRects.title.w / 10) * 3.68,
              center.y + (textRects.title.h / 10) * 2.05,

              center.x - (textRects.title.w / 10) * 1.75,
              center.y + (textRects.title.h / 10) * 2.05,

              center.x + (textRects.title.w / 10) * 3,
              stageSize.h,

              center.x - (textRects.title.w / 10) * 3.68,
              stageSize.h,
            ]}
            strokeWidth={0}
            tension={0}
            closed
            fill="#ef4d5d"
          />
        </Layer>
        <Layer>
          <Rect
            x={textRects.marketplace.x - textRects.marketplace.w / 2 - textGap}
            y={textRects.marketplace.y - textRects.marketplace.h / 2 - textGap}
            width={textRects.marketplace.w + textGap * 2}
            height={textRects.marketplace.h + textGap * 2}
            fill="white"
          />
        </Layer>
      </Stage>

      <img
        src={TextDesignImpact}
        alt="Design Impact"
        id="text--design-impact"
        css={css`
          width: ${(113 / 885) * 100 * globalScale * subtitleScale}%;
          position: absolute;
          transform: translate3d(-50%, -50%, 0);
        `}
        style={{
          top: `${17 + parallax1}%`,
          left: `${15 + 3 * dx * 1.1}%`,
        }}
      />
      <img
        src={TextDesignFutures}
        alt="Design Futures"
        id="text--design-futures"
        css={css`
          width: ${(137 / 885) * 100 * globalScale * subtitleScale}%;
          position: absolute;
          transform: translate3d(-50%, -50%, 0);
        `}
        style={{
          top: `${25 + parallax2}%`,
          left: `${85 + 8 * Math.max(-0.2, dx)}%`,
        }}
      />
      <img
        src={TextDesignMarketplace}
        alt="Design Marketplace"
        id="text--design-marketplace"
        css={css`
          width: ${(228 / 885) * 100 * globalScale * subtitleScale}%;
          position: absolute;
          transform: translate3d(-50%, -50%, 0);
        `}
        style={{
          top: `${75 + Math.max(-10, parallax3)}%`,
          left: `${72 + 5 * dx * -1}%`,
        }}
      />
      <img
        src={SplashLogo}
        alt="Singapore Design Week"
        id="text--title"
        css={css`
          width: ${100 * globalScale}%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate3d(-50%, -50%, 0);
        `}
      />
    </div>
  );
}
