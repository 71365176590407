/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import InteractiveFirstFold from "./interactive-first-fold";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import InteractiveFirstFoldMobile from "./interactive-first-fold-mobile";
import Fade from "react-reveal/Fade";

import "./App.css";

const breakpoints = {
  max: 1366,
  tablet: 1000,
  mobile: 520,
};

const colors = {
  brand: "#ef4d5d",
};

function IconGroup() {
  return (
    <div
      className="icon-group"
      css={css`
        display: flex;
        gap: 10px;

        img.icon {
          height: 28px;
        }

        @media (max-width: ${breakpoints.mobile}px) {
          flex-direction: column;
          margin-top: 0;
        }
      `}
    >
      <a
        href="https://www.instagram.com/designsingapore"
        target="_blank"
        rel="noreferrer"
      >
        <img
          className="icon"
          src="/images/instagram-icon.png"
          alt="instagram icon"
        />
      </a>
      <a
        href="https://www.facebook.com/designsingaporecouncil"
        target="_blank"
        rel="noreferrer"
      >
        <img
          className="icon"
          src="/images/facebook-icon.png"
          alt="facebook icon"
        />
      </a>
      <a
        href="https://www.youtube.com/channel/UCk6BrOjGPFaubX-JtFaE1mg"
        target="_blank"
        rel="noreferrer"
      >
        <img
          className="icon"
          src="/images/youtube-icon.png"
          alt="youtube icon"
        />
      </a>
      <a
        href="https://sg.linkedin.com/company/designsingapore-council"
        target="_blank"
        rel="noreferrer"
      >
        <img
          className="icon"
          src="/images/linkedin-icon.png"
          alt="linkedin icon"
        />
      </a>
    </div>
  );
}

function App() {
  /** CAUTION
   * We're only using this state to switch the landing feature.
   * For other mobile responsive styling, css media queries
   * should be sufficient!
   */
  const [mobile, setMobile] = useState(false);

  const updateIsMobile = () => {
    setMobile(
      window.innerWidth / window.innerHeight < 9 / 10 ||
      window.innerWidth < breakpoints.mobile
    );
  };
  useEffect(() => {
    window.addEventListener("resize", updateIsMobile);
    updateIsMobile();
    return () => {
      window.removeEventListener("resize", updateIsMobile);
    };
  }, []);

  return (
    <div
      css={css`
        display: flex;
        justify-content: center;
        padding: 30px 0;

        @media (max-width: ${breakpoints.mobile}px) {
          padding: 20px 0;
        }
      `}
    >
      <div
        css={css`
          width: 100%;
          max-width: ${breakpoints.max}px;
          display: flex;
          flex-direction: column;
          position: relative;
          gap: 30px;
        `}
      >
        <div
          css={css`
            position: absolute;
            height: 100%;
            pointer-events: none;

            @media (max-width: ${breakpoints.mobile}px) {
              display: none;
            }
          `}
        >
          <h2
            className="date-heading"
            css={css`
              font-family: "DIN Condensed Bold";
              font-size: 3.125rem;
              line-height: ${54 / 60};
              position: sticky;
              top: 70%;
              margin: 0 0 230px 0;
              z-index: 5;
              padding-left: 30px;
              text-transform: uppercase;

              @media screen and (max-width: ${breakpoints.tablet}px) {
                font-size: 2.75rem;
                margin-bottom: 261px;
              }

              @media screen and (max-width: 770px) {
                font-size: 2rem;
                margin-bottom: 285px;
              }

              @media screen and (max-aspect-ratio: 9/10) {
                display: none;
              }
            `}
          >
            16—25
            <br />
            September
            <br />
            2022
          </h2>
        </div>
        <div
          className="first-fold"
          css={css`
            width: 100%;
            height: calc(100vh);
            min-height: 650px;
            box-sizing: border-box;
            padding: 0 30px;

            @media (max-width: ${breakpoints.mobile}px) {
              padding: 0 20px;
            }

            @media (max-aspect-ratio: 9/10) {
              height: auto;
            }
          `}
        >
          <header
            css={css`
              display: flex;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              justify-content: space-between;
              padding: 0 20px;
              box-sizing: border-box;
              z-index: 2;
            `}
          >
            <a
              href="https://www.designsingapore.org/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/images/design-singapore-logo.png"
                alt="Design Singapore logo"
                css={css`
                  width: 60px;
                `}
              />
            </a>
            <div
              css={css`
                @media (max-width: ${breakpoints.mobile}px) {
                  display: none;
                }
              `}
            >
              <IconGroup />
            </div>
          </header>
          {mobile ? <InteractiveFirstFoldMobile /> : <InteractiveFirstFold />}
        </div>
        <p
          className="subheading"
          css={css`
            margin-top: -50px;
            padding: 0 20px;
            font-size: 1.375rem;
            line-height: ${28 / 23};
            display: none;
            @media (max-width: ${breakpoints.mobile}px) {
              display: flex;
            }
          `}
        >
          Are you ready for a new design festival experience in Singapore?
          Feel the pulse in our UNESCO Creative City of Design through
          three defining festival pillars:
        </p>
        <Grid
          className="second-fold"
          css={css`
            margin-top: -20px;
          `}
        >
          <div
            css={css`
              grid-column: 2;
            `}
          >
            <div
              css={css`
                @media (max-width: ${breakpoints.mobile}px) {
                  display: none;
                }
              `}
            >
              <h1
                css={css`
                  text-transform: uppercase;
                  font-size: 5rem;
                  font-family: "DIN Condensed Bold";
                  line-height: ${72 / 78};
                  margin-top: 0.3rem;
                  margin-bottom: 0.875rem;

                  @media (max-width: ${breakpoints.tablet}px) {
                    display: none;
                  }
                `}
              >
                Singapore Design Week returns with a brand new vision this
                September
              </h1>
              <p
                className="subheading"
                css={css`
                  /* margin: 0; */
                  font-size: 1.375rem;
                  line-height: ${28 / 23};
                `}
              >
                Are you ready for a new design festival experience in Singapore?
                Feel the pulse in our UNESCO Creative City of Design through
                three defining festival pillars:
              </p>
            </div>
            <div
              css={css`
                display: flex;
                gap: 30px;
                flex-direction: row;
                overflow: hidden;
                margin-left: -31%;
                margin-top: 150px;
                > div {
                  width: 50%;
                }

                @media (max-width: ${breakpoints.tablet}px) {
                  flex-direction: column;
                  gap: 30px;
                  margin-left: -73px;
                  margin-top: 90px;

                  > div {
                    width: calc( 100% - 73px );
                    padding-left: 73px;
                  }
                }

                @media (max-width: ${breakpoints.mobile}px) {
                  margin-left: 0;

                  > div {
                    width: 100%;
                    padding-left: 0;
                  }
                }
              `}
            >
              <Fade bottom cascade off>
                <div
                  css={css`
              height: 100%;
              position: relative;
              overflow: visible;
            box-sizing: border-box;
              display: grid;
            `}
                >
                  <img
                    src="/images/designfuture.svg"
                    alt="design future"
                    css={css`
                    grid-row: 1;
                    grid-column: 1;
                    @media (max-width: ${breakpoints.tablet}px) {
                      margin-left: -73px;
                      margin-right: -20px;
                    }
                    `}
                  />
                  <div css={css`
                      grid-row: 1;
                      grid-column: 1;
                      justify-self: end;
                      width: 300px; 
                      display: flex;
                      flex-direction: column;
                      z-index: 1;
                      margin-right: 40px;
                      margin-top: 54px;

                      @media (max-width: ${breakpoints.mobile}px) {
                        margin-right: 0;
                        justify-self: center;
                    }
                  `}>
                    <img
                      src="/images/SDW_1.png"
                      alt="design future"
                      css={css`
                    display: block;
                    height: 216px;
                    object-fit: contain;
                    margin-bottom: 10px;
                    `}
                    />
                    <span className="caption1-text">
                      Discover how innovative design can
                      transform the future of the world
                    </span>
                    <span className="caption2-text">
                      Image by Prakash Y, courtesy of Unsplash
                    </span>
                  </div>
                </div>
                <div
                  css={css`
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    @media (max-width: ${breakpoints.mobile}px) {
                      padding-left: 20px;
                      padding-right: 20px;
                    }
                  `}
                >
                  <Cell fontColor={colors.brand}>
                    <h3>
                      Design Futures
                    </h3>
                    <p>
                      Discover the design of the future and the future
                      of design in forward-looking Singapore –
                      where a more positive future is prototyped
                      for Singapore and the world.
                    </p>
                    <p>
                      Join a host of distinguished thought leaders
                      and speakers from across the globe, as they
                      explore design-enabled versions of the future
                      on a 5-to-100 year horizon. Led by an internationally
                      respected Curatorial Director, the inaugural Design
                      Futures symposium provides a fascinating glimpse into
                      how design will transform the future of the world.
                    </p>
                  </Cell>

                  <LinkButton
                    href="https://bit.ly/3xZvHkr"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Sign up for updates <br />
                    on the Design Futures Symposium
                  </LinkButton>
                </div>
              </Fade>
            </div>
          </div>
          <div
            css={css`
              grid-column: 3;
              width: 100%;
              position: relative;
              overflow: hidden;
              padding-left: 30px;

              @media (max-width: ${breakpoints.mobile}px) {
                display: none;
              }
            `}
          >
            <img
              src="/images/right-triangle.svg"
              alt="right triangle"
              css={css`
                object-fit: fill;
                z-index: 0;
                @media (max-width: ${breakpoints.mobile}px) {
                  display: none;
                }
              `}
            />
            <img
              src="/images/right-triangle-mobile.png"
              alt="right triangle"
              css={css`
                display: none;
                @media (max-width: ${breakpoints.mobile}px) {
                  display: block;
                  object-fit: fill;
                  z-index: 0;
                  height: 100%;
                }
              `}
            />
          </div>
        </Grid>
        <Grid className="second-sub-fold">
          <div
            css={css`
              grid-column: 2;
            `}
          >
            <div
              css={css`
                display: flex;
                gap: 30px;
                flex-direction: row;
                overflow: hidden;
                margin-right: -31%;
                margin-top: 120px;
                > div {
                  width: 50%;
                }
                
                @media (max-width: ${breakpoints.tablet}px) {
                  flex-direction: column-reverse;
                  margin-right: -73px;
                  margin-top: 90px;
                  gap: 0px;
                  > div {
                    width: calc( 100% - 73px );
                    padding-left: 0;
                  }
                }

                @media (max-width: ${breakpoints.mobile}px) {
                  margin-right: 0;

                  > div {
                    width: 100%;
                    padding-left: 0;
                  }
                }
              `}
            >
              <Fade bottom cascade off>
                <div
                  css={css`
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;

                    @media (max-width: ${breakpoints.tablet}px) {
                      margin-top: 30px;
                    }

                    @media (max-width: ${breakpoints.mobile}px) {
                      padding-left: 20px;
                      padding-right: 20px;
                    }
                    `}
                >
                  <Cell fontColor={colors.brand}>
                    <h3>
                      DESIGN MARKETPLACE
                    </h3>
                    <p>
                      FIND – Design Fair Asia is Asia’s newest and largest
                      Furniture, Interiors and Design Fair. The definitive
                      platform for design in Asia, FIND will host the largest
                      carefully selected collection of interior brands, key
                      opinion leaders, designers and content from East and West.
                    </p>
                    <p>
                      Join design tastemakers Tony Chambers and Yoko Choy
                      at the FIND – Global Summit as they initiate conversations
                      with global design opinion leaders on topics such as the
                      metaverse, wellbeing, biophilia and sustainability amongst others.
                    </p>
                    <p>Discover EMERGE @ FIND, the first-of-its-kind showcase
                      focused on Southeast Asian design talent. Over 50 designers
                      from Indonesia, Malaysia, Philippines, Singapore, Thailand
                      and Vietnam will unveil fresh works through the lens of materiality.
                    </p>
                    <p>Get more details on <a
                      href="https://www.designfairasia.com/?gclid=Cj0KCQjwzLCVBhD3ARIsAPKYTcSjf8tMG6Se78jAuDv4sqGSgrtvCDgMN52DcO7QidN0rFkanlqEJTkaAk2REALw_wcB"
                      className="inner_link"
                      target="_blank"
                      rel="noreferrer"
                    >FIND – Design Fair Asia</a> here.  </p>
                  </Cell>
                </div>
                <div
                  css={css`
              height: 100%;
              position: relative;
              overflow: visible;
              box-sizing: border-box;
              display: grid;
            `}
                >
                  <img
                    src="/images/designfuture.svg"
                    alt="design future"
                    css={css`
                    grid-row: 1;
                    grid-column: 1;
                    transform: rotateY(180deg);
                    @media (max-width: ${breakpoints.tablet}px) {
                      margin-left: -20px;
                      margin-right: -73px;
                    }
                    `}
                  />
                  <div css={css`
                      grid-row: 1;
                      grid-column: 1;
                      width: 300px; 
                      display: flex;
                      flex-direction: column;
                      z-index: 1;
                      margin-left: 40px;
                      margin-top: 54px;

                      @media (max-width: ${breakpoints.mobile}px) {
                        margin-left: 0;
                        justify-self: center;
                    }
                  `}>
                    <img
                      src="/images/SDW_2.png"
                      alt="design future"
                      css={css`
                    display: block;
                    height: 216px;
                    object-fit: contain;
                    margin-bottom: 10px;
                    `}
                    />
                    <span className="caption1-text">
                      Scribble tables by Lanzavecchia+Wai for De Castelli
                    </span>
                    <span className="caption2-text">
                      Image courtesy of Lanzavecchia+Wai
                    </span>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </Grid>
        <Grid className="second-sub-fold">
          <div
            css={css`
              grid-column: 2;
            `}
          >
            <div
              css={css`
                display: flex;
                gap: 30px;
                flex-direction: row;
                overflow: hidden;
                margin-left: -31%;
                margin-top: 120px;
                > div {
                  width: 50%;
                }

                @media (max-width: ${breakpoints.tablet}px) {
                  flex-direction: column;
                  gap: 30px;
                  margin-left: -73px;
                  margin-top: 90px;

                  > div {
                    width: calc( 100% - 73px );
                    padding-left: 73px;
                  }
                }
              `}
            >
              <Fade bottom cascade off>
                <div
                  css={css`
              height: 100%;
              position: relative;
              overflow: visible;
              box-sizing: border-box;
              display: grid;
            `}
                >
                  <img
                    src="/images/designfuture.svg"
                    alt="design future"
                    css={css`
                    grid-row: 1;
                    grid-column: 1;
                    @media (max-width: ${breakpoints.tablet}px) {
                      margin-left: -73px;
                      margin-right: -20px;
                    }
                    `}
                  />
                  <div css={css`
                      grid-row: 1;
                      grid-column: 1;
                      justify-self: end;
                      width: 347px; 
                      display: flex;
                      flex-direction: column;
                      z-index: 1;
                      margin-right: 40px;
                      margin-top: 54px;
                      @media (max-width: ${breakpoints.mobile}px) {
                        margin-top: 21px;
                        margin-right: 40px;               
                    }
                  `}>
                    <img
                      src="/images/SDW_3.png"
                      alt="design future"
                      css={css`
                    display: block;
                    height: 294px;
                    object-fit: contain;
                    margin-bottom: 10px;

                    @media (max-width: ${breakpoints.mobile}px) {
                      margin-left: -28px;                 
                  }
                    `}
                    />
                  </div>
                  <div css={css`
                      grid-row: 2;
                      grid-column: 1;
                      justify-self: end;
                      width: 300px; 
                      display: flex;
                      flex-direction: column;
                      z-index: 1;
                      margin-right: 40px;

                      @media (max-width: ${breakpoints.mobile}px) {
                        justify-self: end;
                        margin-right: 55px;
                      }
                  `}>
                    <span className="caption1-text">
                      Clockwise from top: AirMesh Pavilion,
                      President*s Design Award Design of the Year 2020
                    </span>
                    <span className="caption2-text">
                      Image courtesy of Carlos Bañón
                    </span>
                    <span className="caption1-text" css={css`
                      margin-top: 16px;
                    `}>
                      Wood from trees felled for urban development
                      are given a second life, and transformed into
                      ethical furniture as part of The Local Tree
                      Project, supported by the Good Design Research initiative
                    </span>
                    <span className="caption2-text">
                      Images courtesy of Roger&Sons
                    </span>
                  </div>
                </div>
                <div
                  css={css`
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    
                    @media (max-width: ${breakpoints.mobile}px) {
                      padding-left: 20px;
                      padding-right: 20px;
                    }
                  `}
                >
                  <Cell fontColor={colors.brand}>
                    <h3>
                      Design Impact
                    </h3>
                    <p>
                      The intersecting crises and challenges of our
                      time call for innovative solutions to address
                      today’s most critical issues. Be inspired by
                      Singapore designers who are rising to tackle
                      society’s biggest questions with bold new initiatives.
                    </p>
                    <p>
                      Experience first-hand some of Singapore's most
                      outstanding, innovative and impactful designs
                      through themed President*s Design Award trails
                      that pay tribute to the nation’s highest design accolade.
                    </p>
                    <p>
                      Get up close to passion projects from Good Design
                      Research, as local designers take their research
                      from the studios to the streets in a special pop-up,
                      showcasing prototypes and solutions that address issues
                      from sustainability to mental health.
                    </p>
                  </Cell>
                </div>
              </Fade>
            </div>
          </div>
        </Grid>
        <Grid className="third-fold"
          css={css`
          margin-top: 48px;
        `}>
          <div
            css={css`
              grid-column: 1;
              width: 100%;
              height: 100%;
              position: relative;
              overflow: hidden;
              padding-right: 30px;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;

              @media (max-width: ${breakpoints.mobile}px) {
                display: none;
              }
            `}
          >
            <img
              src="/images/left-triangle.svg"
              alt="left triangle"
              css={css`
                display: block;
                width: 100%;
                z-index: 0;
                object-fit: cover;
                object-position: right;
                @media (max-width: ${breakpoints.mobile}px) {
                  display: none;
                }
              `}
            />
            <img
              src="/images/left-triangle-mobile.png"
              alt="left triangle"
              css={css`
                display: none;
                @media (max-width: ${breakpoints.mobile}px) {
                  display: block;
                  width: 100%;
                  height: 100%;
                  z-index: 0;
                  object-fit: cover;
                  object-position: right;
                }
              `}
            />
          </div>
          <div
            css={css`
            display: flex;
            flex-direction: column;
            `}
          >
            <h1
              css={css`
                  text-transform: uppercase;
                  font-size: 5rem;
                  font-family: "DIN Condensed Bold";
                  line-height: ${72 / 78};
                  margin-top: 0.3rem;
                  margin-bottom: 0;

                  @media (max-width: ${breakpoints.mobile}px) {
                    font-size: 2.625rem;
                    padding-left: 20px;
                    padding-right: 20px;
                    }
                `}
            >
              be Part of the Festival!
            </h1>
            <div
              css={css`
                display: flex;
                gap: 30px;
                flex-direction: row;
                overflow: hidden;
                margin-right: -17%;
                margin-top: 90px;
                > div {
                  width: 44%;
                }
                > div:first-of-type {
                  width: 56%;
                }
                @media (max-width: ${breakpoints.tablet}px) {
                  flex-direction: column-reverse;
                  gap: 0px;
                  margin-right: 0px;
                  margin-top: 30px;

                  > div {
                    width: 100%;
                  }
                  > div:first-of-type {
                    width: 100%;
                  }
                }
              `}
            >
              <Fade bottom cascade off>
                <div
                  css={css`
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    
                    @media (max-width: ${breakpoints.tablet}px) {
                      margin-top: 30px;
                    }

                    @media (max-width: ${breakpoints.mobile}px) {
                      padding-left: 20px;
                      padding-right: 20px;
                    }
                    `}
                >
                  <Cell fontColor={colors.brand}>
                    <h3>
                      Design Lifestyle Experiences
                    </h3>
                    <p>
                      Immerse in the festivities with district
                      activations that set the city abuzz. First
                      introduced during Singapore Design Week 2018,
                      these vibrant activations have seen locations
                      such as Keong Saik energised with Street of Clans,
                      Chip Bee Gardens come alive with Design District
                      Dialogues and an underutilised multi-storey carpark
                      turned disco pit stop with Urban Design Festival.
                      Stay tuned to discover this year’s unique surprises.  </p>
                  </Cell>
                </div>
                <div
                  css={css`
                    height: 100%;
                    position: relative;
                    overflow: visible;
                    box-sizing: border-box;
                    display: grid;

                    @media (max-width: ${breakpoints.tablet}px) {
                      justify-content: flex-end;
                    }
                  `}
                >
                  <img
                    src="/images/bepart.svg"
                    alt="design future"
                    css={css`
                    grid-row: 1;
                    grid-column: 1;
                    width: 326px;
                    `}
                  />
                  <div css={css`
                      grid-row: 1;
                      grid-column: 1;
                      width: 240px; 
                      display: flex;
                      flex-direction: column;
                      z-index: 1;
                      margin-top: 50px;
                    `}>
                    <img
                      src="/images/SDW_4.png"
                      alt="design future"
                      css={css`
                    display: block;
                    height: 170px;
                    object-fit: contain;
                    margin-bottom: 10px;
                    `}
                    />
                    <span className="caption1-text">
                      Districts across Singapore come alive with Design Lifestyle Experiences
                    </span>
                    <span className="caption2-text">
                      Image courtesy of DesignSingapore Council
                    </span>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </Grid>
        <Grid className="second-sub-fold"
          css={css`
          margin-top: 80px;
        `}>
          <div
            css={css`
            grid-column: 2;
            `}
          >
            <div
              css={css`
                display: flex;
                gap: 30px;
                flex-direction: row;
                overflow: hidden;
                margin-left: -17%;
                > div:first-of-type {
                  width: 44%;
                }
                > div {
                  width: 56%;
                }

                @media (max-width: ${breakpoints.tablet}px) {
                  flex-direction: column;
                  gap: 30px;
                  margin-left: 0px;
                  margin-top: 30px;

                  > div {
                    width: 100%;
                  }
                  > div:first-of-type {
                    width: 100%;
                  }
                }
              `}
            >
              <Fade bottom cascade off>
                <div
                  css={css`
                    height: 100%;
                    position: relative;
                    overflow: visible;
                    box-sizing: border-box;
                    display: grid;
                    justify-items: flex-end;

                    @media (max-width: ${breakpoints.tablet}px) {
                      width: 326px;
                      align-self: flex-start;
                      margin-left: -20px;
                    }
                  `}
                >
                  <img
                    src="/images/bepart.svg"
                    alt="design future"
                    css={css`
                    grid-row: 1;
                    grid-column: 1;
                    transform: rotateY(180deg);
                    `}
                  />
                  <div css={css`
                      grid-row: 1;
                      grid-column: 1;
                      width: 240px; 
                      display: flex;
                      flex-direction: column;
                      z-index: 1;
                      margin-top: 50px;
                    `}>
                    <img
                      src="/images/SDW_5.png"
                      alt="design future"
                      css={css`
                    display: block;
                    height: 170px;
                    object-fit: contain;
                    margin-bottom: 10px;
                    `}
                    />
                    <span className="caption1-text">
                      Chemistry conceptualised a gaming arcade and
                      opened their studio doors for Singapore Design
                      Week 2018, where visitors were invited to peek
                      into the inner workings of the creative consultancy.

                    </span>
                    <span className="caption2-text">
                      Image courtesy of DesignSingapore Council
                    </span>
                  </div>
                </div>
                <div
                  css={css`
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;

                    @media (max-width: ${breakpoints.mobile}px) {
                      padding-left: 20px;
                      padding-right: 20px;
                    }
                      
                    `}
                >
                  <Cell fontColor={colors.brand}>
                    <h3>
                      Design Community Open Doors
                    </h3>
                    <p>
                      Mingle with the local creative community through
                      ground-up design programmes. Visitors can expect
                      open houses, talks, workshops and more as designers,
                      design studios and design enterprises showcase their
                      creative spirit and share their passion for design with the public.
                    </p>
                  </Cell>
                </div>

              </Fade>
            </div>
          </div>
        </Grid>
        <Footer>
          <div className="footer-top">
            <div className="logos">
              <img
                src="/images/sdw-logo.png"
                alt="Singapore Design Week Logo"
                css={css`
                  height: 99px;

                  @media screen and (max-width: ${1000}px) {
                    height: 65px;
                  }
                `}
              />
              <div
                css={css`
                  display: flex;
                  gap: 20px;
                  @media screen and (max-width: ${breakpoints.tablet}px) {
                    gap: 10px;
                  }
                `}
              >
                <a
                  href="https://www.designsingapore.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/images/design-singapore-logo.png"
                    alt="Design Singapore Logo"
                    css={css`
                      height: 70px;
                      @media screen and (max-width: ${1000}px) {
                        height: 50px;
                      }
                    `}
                  />
                </a>
                <a
                  href="https://www.designsingapore.org/about-us/unesco-creative-city-of-design.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/images/city-of-design-logo.png"
                    alt="UNESCO City of Design Logo"
                    css={css`
                      height: 70px;
                      @media screen and (max-width: ${1000}px) {
                        height: 50px;
                      }
                    `}
                  />
                </a>
              </div>
            </div>
            <div className="links">
              <div className="text-group">
                <a
                  href="mailto:sdw@designsingapore.org"
                  className="socials"
                  target="_blank"
                  rel="noreferrer"
                >
                  Contact
                </a>
                <a
                  href="https://bit.ly/3xZvHkr"
                  className="socials"
                  target="_blank"
                  rel="noreferrer"
                >
                  Subscribe
                </a>
              </div>
              <IconGroup />
            </div>
          </div>
          <div className="bottom-text">
            <div className="bottom-text-links">
              <a
                className="small-text"
                href="https://www.designsingapore.org/terms-of-use.html"
                target="_blank"
                rel="noreferrer"
              >
                Terms of use
              </a>
              <a
                className="small-text"
                href="https://www.designsingapore.org/privacy-statement.html"
                target="_blank"
                rel="noreferrer"
              >
                Privacy statement
              </a>
            </div>
            <span className="small-text">
              Copyright © DesignSingapore Council 2003 - 2022{" "}
              <span
                css={css`
                  display: none;

                  @media screen and (max-width: ${breakpoints.mobile}px) {
                    display: inline;
                  }
                `}
              >
                <br />
              </span>
              All Rights Reserved
            </span>
          </div>
        </Footer>
      </div>
    </div>
  );
}

const Grid = styled.div`
  display: grid;
  gap: 0px;
  position: relative;
  grid-template-columns: 1fr 2fr 1fr;
  overflow-x: hidden;

  @media (max-width: ${breakpoints.mobile}px) {
    display: flex;

    &.second-fold {
      display: grid;
      grid-template-columns: 4fr;
      gap: 0;

      & > div:first-of-type {
        position: relative;
        grid-column: 1;
      }

      & > div:last-child {
        position: relative;
        grid-column: 2;

        img {
          position: absolute;
          left: 30px;
          top: 0;
          width: calc(100% - 30px);
          height: 100%;
          object-fit: fill;
        }
      }
    }

    &.second-sub-fold{
      display: grid;
      grid-template-columns: 4fr;
      gap: 0;

      & > div:first-of-type {
        position: relative;
        grid-column: 1;
      }
    }

    &.third-fold {
      display: grid;
      grid-template-columns: 4fr;

      & > div:first-of-type {
        position: relative;

        img {
          position: absolute;
          left: 0;
          top: 0;
          width: calc(100% - 30px);
          height: 100%;
          object-fit: fill;
        }
      }
    }
  }    
  .caption1-text{
    font-size: 0.750rem;
    line-height: 0.938rem;
  }

  .caption2-text{
    margin-top: 16px;
    font-style: italic;
    font-size: 0.688rem;
    line-height: 0.875rem;
  }
  a.inner_link{
    color: ${colors.brand};
    font-weight: bold;
  }
`;

const LinkButton = styled.a`
  margin-top: 1em;
  display: inline-block;
  position: relative;
  background: ${colors.brand};
  font-size: 1.5rem;
  font-family: "DIN Condensed Bold";
  text-transform: uppercase;
  padding: 0.65rem 1rem 0.25rem 1rem;
  color: white;
  text-decoration: none;
  transform: translate(0, 0);
  transition: transform ease 0.3s;
  line-height: 1;
  text-align: center;
  
  @media (max-width: ${breakpoints.mobile}px) {
    margin-left: auto;
    margin-right: auto;
  }

  &:hover {
    cursor: pointer;
    color: white;
    text-decoration: none;
    transform: translate(0, -4px);
  }
`;

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 180px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 30px;
  margin-top: 120px;

  a.socials {
    font-family: "DIN Condensed Bold";
    color: ${colors.brand};
    text-transform: uppercase;
    font-size: 1.8rem;
    text-decoration: underline;
  }

  .footer-top {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: nowrap;

    @media screen and (max-width: ${breakpoints.tablet}px) {
      flex-wrap: wrap;
    }

    @media screen and (max-width: ${breakpoints.mobile}px) {
      flex-wrap: nowrap;
    }
  }

  .logos {
    display: flex;
    align-items: center;
    gap: 50px;
    margin-bottom: 20px;
  }

  .links {
    display: flex;
    gap: 30px;

    @media (max-width: ${breakpoints.tablet}px) {
      flex-direction: column;
    }

    @media (max-width: ${breakpoints.mobile}px) {
      flex-direction: row;
    }

    div.text-group {
      display: flex;
      gap: 30px;
    }
  }

  .small-text {
    color: black;
    font-size: 0.875rem;
  }

  .bottom-text {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: ${breakpoints.tablet}px) {
      flex-direction: column;
      gap: 20px;
      padding: 20px 0;
    }
  }

  .bottom-text-links {
    display: flex;
    gap: 50px;
  }

  @media (max-width: ${breakpoints.mobile}px) {
    padding: 0 20px;
    gap: 20px;
    flex-wrap: wrap;
    flex-direction: column;
    height: auto;

    .links {
      order: 1;
      justify-content: space-between;

      div.text-group {
        gap: 10px;
        flex-direction: column;
      }
    }

    .footer-top {
      flex-direction: column;
      gap: 30px;
    }

    .logos {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      row-gap: 20px;
      width: 100%;
      order: 2;
      flex-wrap: wrap;
    }

    .bottom-text {
      flex-direction: column;
      gap: 20px;
    }

    .icon-group {
      flex-direction: column;
    }

    .bottom-text-links {
      flex-direction: column;
      gap: 10px;
    }
  }
`;

const Cell = styled.div`
  margin-bottom: 1rem;
  width: ${(p) => p.boxWidth};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;

  h3 {
    font-family: "DIN Condensed Bold";
    color: ${(p) => p.fontColor};
    text-transform: uppercase;
    font-size: 2.31rem;
    line-height: 0.9;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
  }

  h4 {
    font-family: "DIN Condensed Bold";
    color: ${(p) => p.fontColor};
    text-transform: uppercase;
    font-size: 2.0625rem;
    line-height: 0.9;
    margin-top: 2rem;
    margin-bottom: 0.2rem;
  }

  p {
    margin: 0;
    margin-top: 22px;
    font-size: 1rem;
    line-height: ${20 / 16};
  }
  
  p:first-of-type {
    margin-top: 0;
  }

  @media screen and (max-width: ${breakpoints.tablet}px) {
    width: 100%;
  }

  @media (max-width: ${breakpoints.tablet}px) {
    margin-bottom: 0rem;
  }

  @media (max-width: ${breakpoints.mobile}px) {
    width: 100%;
    flex-wrap: wrap;
  }
`;

export default App;
